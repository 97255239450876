<template>
  <v-row align="center" justify="center" style="height: 100vh" bg-color="red">
    <v-col sm="12" md="4">
      <div align="center">
        <v-img aspect-ratio="1" width="150" :src="logo_uri" />
      </div>
      <div class="text-h6 text-center">Ingresa tu nueva contraseña</div>
      <div class="text-subtitle-2 text-center"></div>
      <form @submit.prevent="submit" class="py-4">
        <v-text-field
          name="password"
          label="Contraseña"
          v-model="values.password"
          :error-messages="v$.password.$errors.map((e) => e.$message)"
          autocomplete="password"
          :type="show1 ? 'text' : 'password'"
          :append-inner-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append-inner="show1 = !show1"
        ></v-text-field>
        <v-text-field
          name="password_confirmation"
          label="Confirmar Contraseña"
          v-model="values.password_confirmation"
          :error-messages="v$.password_confirmation.$errors.map((e) => e.$message)"
          autocomplete="password_confirmation"
          :type="show2 ? 'text' : 'password'"
          :append-inner-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append-inner="show2 = !show2"
        ></v-text-field>
        <div class="text-center py-4">
          <v-btn block size="large" class="me-4" type="submit" color="primary">
            Recuperar
          </v-btn>
        </div>
      </form>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref } from "vue"
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import Layout from "../../Layouts/Blank.vue";
import { usersPasswords as passwordsApi } from "../../api";

defineOptions({ layout: Layout });
defineProps(["logo_uri"])

const show1 = ref(false);
const show2 = ref(false);

const initialState = {
  password: "",
  password_confirmation: "",
  reset_password_token: window.location.href.split("=").pop(),
}

const values = ref(initialState)

const samePassword = (value) => value == values.value.password;

const rules = {
  password: {
    required: helpers.withMessage("El password es requerido", required),
    $autoDirty: true,
  },
  password_confirmation: {
    samePassword: helpers.withMessage( "La contraseña no coincide", samePassword ),
    $autoDirty: true,
  }
}

const v$ = useVuelidate(rules, values);

const submit = async () => {
  const validForm = await v$.value.$validate();
  if (!validForm) return;

  const form = inertiaForm({
    user: { ...values.value },
  });
  passwordsApi.update({ form });
};
</script>
