<template>
  <v-row justify="center">
    <v-col cols="12">
      <p class="text-center text-md-h4 text-no-wrap">{{ props.user.name }}</p>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col sm="12" md="5">
      <v-tabs v-model="tab" color="primary" grow>
        <v-tab value="0">Detalles</v-tab>
        <v-tab value="1">Contraseña</v-tab>
      </v-tabs>
      <v-card style="min-height: 50vh">
        <v-card-text>
          <v-window v-model="tab" class="pt-12">
            <v-window-item value="0">
              <UserForm :user="user" :areas="areas" :readonly="true" />
            </v-window-item>

            <v-window-item value="1">
              <ChangePassword :user="user" />
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref } from "vue";
import ChangePassword from "../../components/ChangePassword.vue";
import UserForm from "./_form.vue";

const props = defineProps(["user", "areas"]);
const tab = ref();
</script>
