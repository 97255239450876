<template>
  <div id="fumsByMonth">
    <div class="d-flex justify-end mb-6">
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-palette-outline" v-bind="props" size="x-small"></v-btn>
        </template>

        <v-sheet min-width="300">
        <v-color-picker v-model="color" hide-canvas show-sliders hide-inputs hide-swatches
          @update:modelValue="updateOptions"></v-color-picker>
        </v-sheet>
      </v-menu>
    </div>
    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script setup>
import { ref } from "vue"
const props = defineProps(["data"])

const color = ref("#c62828")

const series = [{
  name: "FUMs",
  data: Object.values(props.data)
}]

const chartOptions = ref({
  chart: {
    height: 400,
    type: 'line',
    zoom: {
      enabled: false
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: [color.value],
  stroke: {
    curve: 'straight'
  },
  title: {
    text: 'FUMs por mes',
    align: 'left'
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  xaxis: {
    categories: Object.keys(props.data),
  },
  yaxis: {
    min: 0,
    max: 20
  }
})

const updateOptions = () => {
  chartOptions.value = {
    colors: [color.value],
  }
}
</script>
