<template>
  <v-sheet class="pa-1">
    <div v-if="props.details.length >= 1" class="pb-4">
      <div class="text-h6 mb-2">Comentarios anteriores</div>
      <v-sheet elevation="2" rounded class="mx-auto pt-1">
        <v-virtual-scroll :items="props.details" height="150">
          <template v-slot:default="{ item }">
            <v-list-item :title="item.name">
              <template v-slot:prepend>
                <v-icon color="primary">mdi-account-outline</v-icon>
              </template>

              <template v-slot:subtitle>
                <div v-html="getContent(item)"></div>
              </template>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-sheet>
    </div>

    <div class="text-h6 pb-8" v-else>Aun no hay comentarios</div>

    <form @submit.prevent="submit">
      <v-textarea
        v-model="state.content"
        :error-messages="v$.content.$errors.map((e) => e.$message)"
        label="Comentarios"
        prepend-inner-icon="mdi-comment"
        rows="1"
        auto-grow
      ></v-textarea>
      <v-row justify="end" class="pb-4">
        <v-btn class="me-3" type="submit" color="primary"> Comentar </v-btn>
      </v-row>
    </form>
  </v-sheet>
</template>

<script setup>
import { ref } from "vue"
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { format as formatFns } from "date-fns";
import { formatJournals } from "~/api";

const props = defineProps(["format", "details"]);

const getDate = (timestamp) => {
  const date = new Date(timestamp);
  return formatFns(date, "dd/MM/yyyy");
};

const getContent = (item) => {
  return `<span class="text-primary">${getDate(item.created_at)}</span> &mdash; ${item.content}`;
}

const initialState = {
  content: ""
}

const state = ref(initialState)


const rules = {
  content: {
    required: helpers.withMessage("Ingresa un comentario", required),
    $autoDirty: true,
  }
}

const v$ = useVuelidate(rules, state);

const submit = async () => {
  const validForm = await v$.value.$validate();
  if (!validForm) return;

  const form = inertiaForm({
    format_journal: {
      ...state.value,
      category: "comment",
      format_id: props.format.id,
    },
  });
  formatJournals.create({ form });
};
</script>
