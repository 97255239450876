<script setup>
import { computed } from "vue";
import DataTable from "../../components/DataTable.vue";
import { organizations as organizationsApi } from "../../api";

defineProps(["organizations", "can"]);

const headers = ["Nombre", "Direccion", "Codigo Postal", "Estado", "Municipio"];

const paths = computed(() => organizationsApi);
</script>

<template>
  <v-sheet>
    <DataTable :items="organizations" :headers="headers" :paths="paths" :can="can" />
  </v-sheet>
</template>
