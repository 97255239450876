<template>
  <form @submit.prevent="submit">
    <v-text-field
      label="Email"
      v-model="state.email"
      :error-messages="v$.email.$errors.map((e) => e.$message)"
      :readonly="props.readonly"
      autocomplete="username"
    ></v-text-field>
    <v-text-field
      label="Nombre"
      v-model="state.name"
      :error-messages="v$.name.$errors.map((e) => e.$message)"
      :readonly="props.readonly"
    ></v-text-field>
    <v-text-field
      label="Puesto"
      v-model="state.job"
      :error-messages="v$.job.$errors.map((e) => e.$message)"
      :readonly="props.readonly"
    ></v-text-field>
    <v-select
      label="Area"
      :items="areas"
      item-title="name"
      item-value="id"
      v-model="state.area_id"
      :error-messages="v$.area_id.$errors.map((e) => e.$message)"
      :disabled="props.readonly"
    ></v-select>
    <v-text-field
      label="Contraseña"
      v-model="state.password"
      :error-messages="v$.password.$errors.map((e) => e.$message)"
      :readonly="props.readonly"
      :type="show ? 'text' : 'password'"
      :append-inner-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append-inner="show = !show"
      autocomplete="current-password"
      v-if="action === 'new'"
    ></v-text-field>
    <v-row justify="end" class="py-4" v-if="!readonly">
      <InertiaLink :href="usersApi.list.pathTemplate" class="inertia-link" as="v-btn">
        <v-btn class="me-4" color="grey-darken-2"> Regresar </v-btn>
      </InertiaLink>
      <v-btn class="me-4" type="submit" color="primary"> Guardar </v-btn>
    </v-row>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email as eMail } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { users as usersApi } from "../../api";

const props = defineProps(["user", "areas", "readonly", "action"]);
const show = ref(false);

const initialState = {
  name: props.user.name,
  email: props.user.email,
  job: props.user.job,
  area_id: props.user.area_id,
  password: ""
};

const state = ref({ ...initialState });

const rules = {
  name: {
    required: helpers.withMessage("El nombre es requerido", required),
    $autoDirty: true,
  },
  job: {
    required: helpers.withMessage("El puesto es requerido", required),
    $autoDirty: true,
  },
  area_id: {
    required: helpers.withMessage("El area es requerida", required),
    $autoDirty: true,
  },
  email: {
    required: helpers.withMessage("El email es requerido", required),
    eMail: helpers.withMessage("El email es invalido", eMail),
    $autoDirty: true,
  },
  password: {
    required: helpers.withMessage("La contraseña es requerida", required),
    $autoDirty: true,
  },
};

const v$ = useVuelidate(rules, state);

const submit = async () => {
  const validForm = await v$.value.$validate();
  if (!validForm || sameValues()) return;
  if (props.action === "edit" && sameValues) return;

  const form = inertiaForm({
    user: {
      ...state.value
    },
  });

  alert(JSON.stringify({ form }, null, 2));
  if (props.action === "edit") {
    const { id } = props.user;
    usersApi.update({ params: { id }, form });
  } else if (props.action === "new") {
    usersApi.create({ form });
  }
};

const sameValues = () =>
  JSON.stringify(state.value) === JSON.stringify(initialState);
</script>
