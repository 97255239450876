// JsFromRoutes CacheKey a75348360aee12167ca7d61aca39cef3
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  new: definePathHelper('get', '/users/password/new'),
  edit: definePathHelper('get', '/users/password/edit'),
  update: definePathHelper('patch', '/users/password'),
  create: definePathHelper('post', '/users/password'),
}
