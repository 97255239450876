// JsFromRoutes CacheKey 56036671c89cef0b7100c9bc6bc3fdef
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  list: definePathHelper('get', '/formatos'),
  create: definePathHelper('post', '/formatos'),
  new: definePathHelper('get', '/formatos/new'),
  edit: definePathHelper('get', '/formatos/:id/edit'),
  get: definePathHelper('get', '/formatos/:id'),
  update: definePathHelper('patch', '/formatos/:id'),
  complete: definePathHelper('post', '/formatos/:id/complete'),
  deactivate: definePathHelper('post', '/formatos/:id/deactivate'),
}
