<template>
  <form @submit.prevent="submit">
    <p hidden>
      <v-text-field
        name="username"
        autocomplete="username"
        readonly
      ></v-text-field>
    </p>
    <v-text-field
      name="current_password"
      label="Conraseña Actual"
      autocomplete="current-password"
      v-model="state.current_password"
      :error-messages="v$.current_password.$errors.map((e) => e.$message)"
      :type="show1 ? 'text' : 'password'"
      :append-inner-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append-inner="show1 = !show1"
    ></v-text-field>
    <v-text-field
      name="password"
      label="Nueva Contraseña"
      autocomplete="new-password"
      v-model="state.password"
      :error-messages="v$.password.$errors.map((e) => e.$message)"
      :type="show2 ? 'text' : 'password'"
      :append-inner-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append-inner="show2 = !show2"
    ></v-text-field>
    <v-text-field
      name="confirm_password"
      label="Confirmar Nueva Contraseña"
      autocomplete="new-password"
      v-model="state.confirm_password"
      :error-messages="v$.confirm_password.$errors.map((e) => e.$message)"
      :type="show3 ? 'text' : 'password'"
      :append-inner-icon="show3 ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append-inner="show3 = !show3"
    ></v-text-field>
    <div class="text-center py-4">
      <v-btn block size="large" class="me-4" type="submit" color="primary">
        Cambiar Contraseña
      </v-btn>
    </div>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { minLength, required, helpers } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { profiles as profilesApi } from "../api";

const props = defineProps(["user"]);

const show1 = ref(false);
const show2 = ref(false);
const show3 = ref(false);

const initialState = {
  password: "",
  current_password: "",
  confirm_password: "",
};

const state = ref(initialState);

const samePassword = (value) => value == state.value.password;

const rules = {
  password: {
    required: helpers.withMessage(
      "Tu contraseña no puede estar vacia",
      required
    ),
    minLengthValue: helpers.withMessage(
      "Tu nueva contraseña debe tener al menos 6 caracteres",
      minLength(6)
    ),
    $autoDirty: true,
  },
  current_password: {
    required: helpers.withMessage(
      "Tu contraseña no puede estar vacia",
      required
    ),
    $autoDirty: true,
  },
  confirm_password: {
    required: helpers.withMessage(
      "Tu contraseña no puede estar vacia",
      required
    ),
    samePassword: helpers.withMessage(
      "La contraseña no coincide",
      samePassword
    ),
    $autoDirty: true,
  },
};

const v$ = useVuelidate(rules, state);

const submit = async () => {
  const validForm = await v$.value.$validate();
  if (!validForm) return;

  const { id } = props.user;

  const form = inertiaForm({
    user: {
      ...state.value,
      id,
    },
  });

  profilesApi.update({ params: { id }, form });
};
</script>
