<template>
    <form @submit.prevent="submit">
        <v-row>
            <v-col cols="9">
                <v-text-field label="buscar FUM por id" v-model="state.id"
                    :error-messages="v$.id.$errors.map((e) => e.$message)" density="compact"></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-btn class="mx-4" type="submit" color="primary" size="large"> Cerrar </v-btn>
            </v-col>
        </v-row>
    </form>
</template>

<script setup>
import { ref } from "vue";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { ninja as ninjaApi } from "~/api"

const initialState = {
    id: ""
}

const state = ref({ ...initialState });

const rules = {
    id: {
        required: helpers.withMessage("El id es requerido", required),
        $autoDirty: true,
    },
}

const v$ = useVuelidate(rules, state);

const submit = async () => {
    const validForm = await v$.value.$validate();
    if (!validForm) return;

    const form = inertiaForm({
        format: {
            ...state.value
        },
    });

    ninjaApi.approveAndCloseFormat({ form })
}
</script>
