<script setup>
import { computed } from "vue";
import DataTable from "../../components/DataTable.vue";
import { formats as formatsApi } from "../../api";

defineProps(["formats", "can"]);
const headers = ["Empleado", "Numero de empleado", "Nuevo puesto", "Status", "Fecha"];
const paths = computed(() => formatsApi);
</script>

<template>
  <v-sheet>
    <DataTable
      :items="formats"
      :headers="headers"
      :paths="paths"
      :tool-bar="{ calendar: true, search: true }"
      :can="can"
    />
  </v-sheet>
</template>
