<script setup>
import { computed } from "vue";
import DataTable from "../../components/DataTable.vue";
import { areas as areasApi } from "../../api";

defineProps(["areas", "can"]);
const headers = ["Nombre"];
const paths = computed(() => areasApi);
</script>

<template>
  <v-sheet>
    <DataTable :items="areas" :headers="headers" :paths="paths" :can="can" />
  </v-sheet>
</template>
