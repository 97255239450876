// JsFromRoutes CacheKey c0eb45516ad073a7169960d85dfe15ad
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  list: definePathHelper('get', '/areas'),
  create: definePathHelper('post', '/areas'),
  new: definePathHelper('get', '/areas/new'),
  edit: definePathHelper('get', '/areas/:id/edit'),
  get: definePathHelper('get', '/areas/:id'),
  update: definePathHelper('patch', '/areas/:id'),
}
