<script setup>
import { computed } from "vue";
import DataTable from "../../components/DataTable.vue";
import { users as usersApi } from "../../api";

defineProps(["users", "can"]);
const headers = ["Nombre", "E-mail", "Area"];

const paths = computed(() => usersApi);
</script>

<template>
  <v-sheet>
    <DataTable :items="users" :headers="headers" :paths="paths" :can="can" />
  </v-sheet>
</template>
