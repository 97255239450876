<template>
    <v-row>
        <v-col cols="9">
            <div class="text-h6">Enviar email de bienvenida a todos los usuarios</div>
        </v-col>
        <v-col cols="3">
            <v-btn class="mx-4" color="primary" size="large" @click="submit"> Enviar </v-btn>
        </v-col>
    </v-row>
</template>

<script setup>
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { ninja as ninjaApi } from "~/api"

const submit = async () => {
const form = inertiaForm({})
ninjaApi.sendWelcomeInstructions({ form })
}
</script>
