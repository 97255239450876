<template>
  <v-row justify="center">
    <v-col md="8" sm="12">
      <v-sheet>
        <div class="text-subtitle-1 text-center pb-4">
          Formato unico de movimiento
        </div>
        <div class="text-h5 text-center pb-8">
          {{ format.employee_name }} - {{ format.employee_number }}
        </div>
        <v-row justify="center">
          <v-col md="6" sm="12">
            <p>
              <span class="font-weight-bold">Puesto actual:</span>
              {{ format.current_position_name }}
            </p>
          </v-col>
          <v-col md="6" sm="12">
            <p>
              <span class="font-weight-bold">Nuevo puesto:</span>
              {{ format.new_position_name }}
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="6" sm="12">
            <p>
              <span class="font-weight-bold">Jefe actual:</span>
              {{ format.current_boss_name }}
            </p>
          </v-col>
          <v-col md="6" sm="12">
            <p>
              <span class="font-weight-bold">Jefe de la vacante:</span>
              {{ format.vacant_boss_name }}
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="6" sm="12">
            <p>
              <span class="font-weight-bold">Jefe coordinador de la vacante:</span>
              {{ format.vacant_coordinator_name }}
            </p>
          </v-col>
          <v-col md="6" sm="12">
            <p>
              <span class="font-weight-bold">Creado por:</span>
              {{ format.created_by_name }}
            </p>
          </v-col>
        </v-row>
        <br class="my-4">
        <div v-if="format.finished">
          <h3 class="text-primary">COMENTARIOS</h3>
          <CommentList :details="comments" />
          <h3 class="text-primary">APROBACIONES</h3>
          <ApprovedList :details="approved" />
          <div class="py-4"></div>
          <v-row justify="end">
            <InertiaLink :href="formatsApi.list.pathTemplate" class="inertia-link" as="v-btn">
              <v-btn class="me-4" color="grey-darken-2"> Regresar </v-btn>
            </InertiaLink>
          </v-row>
        </div>
        <div v-else>
          <v-tabs fixed-tabs color="primary" class="mb-6" v-model="tab">
            <v-tab value="C"> COMENTARIOS </v-tab>
            <v-tab value="A"> APROBACIONES </v-tab>
            <v-tab value="M"> ADMINISTRAR </v-tab>
          </v-tabs>

          <v-window v-model="tab">
            <v-window-item value="C">
              <CommentWindow :format="format" :details="comments" />
            </v-window-item>
            <v-window-item value="A">
              <ApprovedWindow :format="format" :details="approved" :can="can" />
            </v-window-item>
            <v-window-item value="M">
              <ManageWindow :format="format" :can="can" />
            </v-window-item>
          </v-window>

          <div class="py-4">
            <InertiaLink :href="formatsApi.list.pathTemplate" class="inertia-link" as="v-btn">
              <v-btn class="me-4" color="grey-darken-2"> Regresar </v-btn>
            </InertiaLink>
          </div>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, computed } from "vue";
import ApprovedWindow from "./components/ApprovedWindow.vue"
import CommentWindow from "./components/CommentWindow.vue"
import CommentList from "./components/CommentList.vue"
import ApprovedList from "./components/ApprovedList.vue";
import ManageWindow from "./components/ManageWindow.vue";
import { formats as formatsApi } from "../../api";

const props = defineProps(["format", "details", "can"]);

const tab = ref();

const comments = computed(() =>
  props.details.filter((item) => item?.category === "comment")
);
const approved = computed(() =>
  props.details.filter((item) => item?.category === "approval")
);
</script>
