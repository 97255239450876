<template>
  <form @submit.prevent="submit">
    <v-text-field label="Nombre" v-model="values.name"
      :error-messages="v$.name.$errors.map((e) => e.$message)"></v-text-field>
    <v-text-field label="Dirección" v-model="values.address"
      :error-messages="v$.address.$errors.map((e) => e.$message)"></v-text-field>
    <v-text-field label="Código Postal" v-model="values.zip_code"
      :error-messages="v$.zip_code.$errors.map((e) => e.$message)"></v-text-field>
    <v-select label="Estado" v-model="values.state" :items="states"
      :error-messages="v$.state.$errors.map((e) => e.$message)"></v-select>
    <v-text-field label="Municipio" v-model="values.city"
      :error-messages="v$.city.$errors.map((e) => e.$message)"></v-text-field>
    <v-row justify="end" class="pb-4">
      <InertiaLink :href="organizationsApi.list.pathTemplate" class="inertia-link" as="v-btn">
        <v-btn class="me-4" color="grey-darken-2"> Regresar </v-btn>
      </InertiaLink>
      <v-btn class="me-4" type="submit" color="primary"> Guardar </v-btn>
    </v-row>
  </form>
</template>

<script setup>
import { ref } from "vue"
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { organizations as organizationsApi } from "../../api";

const props = defineProps(["organization", "action"]);

const initialState = { ...props.organization }

const values = ref(initialState)

const requiredMessage = "Este campo es necesario"

const rules = {
  name: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  address: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  state: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  city: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  zip_code: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  }
}

const v$ = useVuelidate(rules, values);

const sameValues = () => JSON.stringify(values.value) === JSON.stringify(initialState);

const submit = async () => {
  const validForm = await v$.value.$validate();
  if (!validForm) return;
  if (props.action === "edit" && sameValues) return;

  const { id } = props.organization;

  const form = inertiaForm({
    organization: { ...values.value },
  });

  if (props.action === "edit") {
    organizationsApi.update({ params: { id }, form });
  } else if (props.action === "new") {
    organizationsApi.new({ form });
  }
};

const states = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Ciudad de México",
  "Coahuila",
  "Colima",
  "Durango",
  "Estado de México",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas"
]
</script>
