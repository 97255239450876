<template>
  <v-app id="inspire">
    <v-app-bar color="secondary-darken-1">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="primary" />

      <v-toolbar-title>CEDIS - FUM</v-toolbar-title>
      <AvatarMenu :user="$page.props.auth.user" />
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" color="grey-lighten-4">
      <v-divider></v-divider>
      <v-list>
        <InertiaLink
          v-for="link in links"
          :key="link.route"
          :href="link.route"
          role="navigation"
          class="inertia-link"
        >
          <v-list-item :value="link.route" color="primary" link v-if="$page.props.auth.can.read[link.resource]">
            <template v-slot:prepend>
              <v-icon :icon="link.icon" />
            </template>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </InertiaLink>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container class="pt-12">
        <FlashMessages />
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref } from "vue";
import AvatarMenu from "../components/AvatarMenu.vue";
import FlashMessages from "../components/FlashMessages.vue";

const drawer = ref(false);
</script>

<script>
export default {
  computed: {
    links() {
      return [
        {
          icon: "mdi-file-table-outline",
          text: "Formatos",
          route: this.$api.formats.list.path(),
          resource: "formats",
        },
        {
          icon: "mdi-chart-donut-variant",
          text: "Estadisticas",
          route: this.$api.dashboard.list.path(),
          resource: "dashboard",
        },
        {
          icon: "mdi-texture-box",
          text: "Areas",
          route: this.$api.areas.list.path(),
          resource: "areas",
        },
        {
          icon: "mdi-account-multiple-outline",
          text: "Usuarios",
          route: this.$api.users.list.path(),
          resource: "users",
        },
        {
          icon: "mdi-account-tie",
          text: "Puestos",
          route: this.$api.positions.list.path(),
          resource: "positions",
        },
        {
          icon: "mdi-domain",
          text: "Cedis",
          route: this.$api.organizations.list.path(),
          resource: "organizations",
        },
      ];
    },
  },
};
</script>
