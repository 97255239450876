// Vuetify plugin
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const defaultLightTheme = {
  dark: false,
  colors: {
    background: '#FAFAFA',
    surface: '#FAFAFA',
    primary: '#C62828',
    'primary-darken-1': '#B71C1C',
    secondary: '#FBC02D',
    'secondary-darken-1': '#F9A825',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
}

export const vuetifyPlugin = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'defaultLightTheme',
    themes: {
      defaultLightTheme,
    },
  },
  ssr: true
})
