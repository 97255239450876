<template>
    <v-sheet class="pa-1" justify="center">
        <v-row class="py-4" justify="center">
            <v-col cols="9">Cerrar el proceso de este FUM</v-col>
            <v-col cols="3">
                <v-btn @click="completeFum" color="primary" :disabled="!can.complete">CERRAR</v-btn>
            </v-col>
        </v-row>
        <div v-if="can.delete">
            <v-divider></v-divider>
            <div class="text-h5 py-4" align="center">Eliminar este FUM</div>
            <v-row class="py-4" justify="center">
                <v-col cols="9">
                    <v-text-field :label="confirmMessage" v-model="state.confirm"
                        :error-messages="v$.confirm.$errors.map((e) => e.$message)" density="compact"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-btn @click="deleteFum" color="primary">Eliminar</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>
<script setup>
import { ref } from "vue";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { formats as formatsApi } from "~/api";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const props = defineProps(["format", "can"]);

const confirmMessage = "Escribe ELIMINAR para poder eliminar este FUM";

const initialState = {
    confirm: "",
    reason: ""
}

const state = ref(initialState)

const equalsValue = (value) => value === "ELIMINAR"

const rules = {
    confirm: {
        equalsValue: helpers.withMessage(confirmMessage, equalsValue),
        $autoDirty: true,
    }
}

const v$ = useVuelidate(rules, state);

const completeFum = async () => {
    const { id } = props.format;
    const form = inertiaForm({
        format: {
            finished: true,
        },
    });

    formatsApi.complete({ params: { id }, form });
}

const deleteFum = async () => {
    const validForm = await v$.value.$validate();
    if (!validForm) return;
    const { id } = props.format;
    const form = inertiaForm({
        format: {
            active: false,
            reason: state.value.reason
        },
    });

    formatsApi.deactivate({ params: { id }, form });
}
</script>
