<script setup>
defineProps(["user"]);

const getAvatarName = (email) => {
  if (email === undefined) return "OR";

  let initials = "";
  email
    .split(".")
    .slice(0, 2)
    .forEach((word) => (initials += word.slice(0, 1)));

  return initials.toUpperCase();
};
</script>

<script>
export default {
  computed: {
    signout() {
      return this.$api.usersSessions.destroy.path();
    },
    profile() {
      return this.$api.profiles.list.path();
    },
  },
};
</script>

<template>
  <v-menu min-width="200px" rounded location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props">
        <v-avatar color="primary">
          <span>{{ getAvatarName(user.email) }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <div class="mx-auto text-center">
          <v-avatar color="primary" size="small">
            <span>{{ getAvatarName(user.email) }}</span>
          </v-avatar>
          <div class="text-h6">{{ user.name }}</div>
          <p class="text-caption mt-1">
            {{ user.email }}
          </p>
          <v-divider class="my-3"></v-divider>
          <InertiaLink :href="profile" class="inertia-link" as="v-btn">
            <v-btn rounded variant="text"> Perfil </v-btn>
          </InertiaLink>
          <v-divider class="my-3"></v-divider>
          <InertiaLink
            :href="signout"
            method="delete"
            class="inertia-link"
            as="v-btn"
          >
            <v-btn rounded variant="text"> Desconectar </v-btn>
          </InertiaLink>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
