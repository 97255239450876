import { createApp, h } from "vue";
import { createInertiaApp, Head, Link } from "@inertiajs/vue3";
import VueApexCharts from "vue3-apexcharts";
import { vuetifyPlugin } from "./vuetify";
import { resolvePage } from "./pages";
import api from "./api";

export default function () {
  createInertiaApp({
    resolve: resolvePage,
    progress: {
      // The delay after which the progress bar will appear, in milliseconds...
      delay: 50,

      // The color of the progress bar...
      color: "#c62828",

      // Whether to include the default NProgress styles...
      includeCSS: true,

      // Whether the NProgress spinner will be shown...
      showSpinner: false,
    },
    setup({ el, App, props, plugin }) {
      const app = createApp({ render: () => h(App, props) });
      app.use(plugin);
      app.use(vuetifyPlugin);
      app.use(VueApexCharts)

      app.component("InertiaHead", Head);
      app.component("InertiaLink", Link);

      app.config.globalProperties.msg = "hello";
      app.config.globalProperties.$api = api;

      app.mount(el);
    },
  });
}

// export function createApp (options) {
//   return createInertiaApp({
//     resolve: resolvePage,
//     setup ({ plugin, app, props, el }) {
//       Vue.use(plugin)

//       const vueApp = new Vue({
//         render: h => h(app, props),
//         metaInfo: {
//           titleTemplate (title) {
//             return title ? `${title} - CEDIS-FUMS` : 'CEDIS-FUMS'
//           }
//         }
//       })

//       if (el) {
//         vueApp.$mount(el)
//         return vueApp
//       }
//     },
//     ...options
//   })
// }
