<template>
  <form @submit.prevent="submit">
    <v-row>
      <v-col sm="12" md="6">
        <v-text-field
          model-value="Cambio de puesto"
          label="Tipo de movimiento"
          readonly
        ></v-text-field>

        <v-textarea
          v-model="state.justification"
          :error-messages="v$.justification.$errors.map((e) => e.$message)"
          label="Justificacion"
        ></v-textarea>
      </v-col>

      <v-col sm="12" md="6">
        <v-radio-group
          v-model="state.permanent"
          :error-messages="v$.permanent.$errors.map((e) => e.$message)"
          inline
          label="Motivo del cambio"
          @change="setTerminationDate"
        >
          <v-radio label="Temporal" :value="false" color="primary"></v-radio>
          <v-radio label="Permanente" :value="true" color="primary"></v-radio>
        </v-radio-group>

        <v-checkbox
          v-model="state.training"
          label="¿Solicita Entrenamiento?"
          color="primary"
        ></v-checkbox>

        <v-checkbox
          v-model="state.job_posting"
          label="¿Solicitud a travez de JobPosting?"
          color="primary"
        ></v-checkbox>
      </v-col>
    </v-row>

    <div class="pb-4">
      <v-divider></v-divider>
    </div>

    <v-row>
      <v-col sm="12" md="6">
        <v-text-field
          v-model="state.employee_name"
          :counter="10"
          :error-messages="v$.employee_name.$errors.map((e) => e.$message)"
          label="Nombre de empleado"
        ></v-text-field>
      </v-col>

      <v-col sm="12" md="6">
        <v-text-field
          v-model="state.employee_number"
          :counter="7"
          :error-messages="v$.employee_number.$errors.map((e) => e.$message)"
          label="Numero de empleado"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" md="6">
        <VueDatePicker
          v-model="state.creation_date"
          :max-date="new Date()"
          :enable-time-picker="false"
          auto-apply
          :format="formatDate"
          locale="es"
          week-start="0"
        >
          <template #trigger>
            <v-text-field
              label="Fecha de creación"
              :model-value="formatDate(state.creation_date)"
              :error-messages="v$.creation_date.$errors.map((e) => e.$message)"
            />
          </template>
        </VueDatePicker>
      </v-col>

      <v-col sm="12" md="6">
        <VueDatePicker
          v-model="state.termination_date"
          :min-date="new Date()"
          :enable-time-picker="false"
          auto-apply
          :format="formatDate"
          locale="es"
          week-start="0"
          v-if="state.permanent === false"
        >
          <template #trigger>
            <v-text-field
              label="Fecha fin"
              :model-value="formatDate(state.termination_date)"
              :error-messages="v$.employee_name.$errors.map((e) => e.$message)"
            />
          </template>
        </VueDatePicker>
        <v-text-field v-else disabled></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="6">
        <v-select
          v-model="state.current_position_id"
          :items="positions"
          item-title="name"
          item-value="id"
          :error-messages="v$.current_position_id.$errors.map((e) => e.$message)"
          label="Puesto actual"
        ></v-select>
      </v-col>

      <v-col sm="12" md="6">
        <v-select
          v-model="state.new_position_id"
          :items="positions"
          item-title="name"
          item-value="id"
          :error-messages="v$.new_position_id.$errors.map((e) => e.$message)"
          label="Puesto a ocupar"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" md="6">
        <v-select
          v-model="state.current_boss_id"
          :items="persons"
          persistent-hint
          item-title="name"
          item-value="id"
          :error-messages="v$.current_boss_id.$errors.map((e) => e.$message)"
          label="Jefe actual"
        ></v-select>
      </v-col>

      <v-col sm="12" md="6">
        <v-select
          v-model="state.vacant_boss_id"
          :items="persons"
          persistent-hint
          item-title="name"
          item-value="id"
          :error-messages="v$.vacant_boss_id.$errors.map((e) => e.$message)"
          label="Jefe de la vacante"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" md="6" offset="md-6">
        <v-select
          v-model="state.vacant_coordinator_id"
          :items="persons"
          persistent-hint
          item-title="name"
          item-value="id"
          :error-messages="v$.vacant_coordinator_id.$errors.map((e) => e.$message)"
          label="Jefe coordinador de la vacante"
        ></v-select>
      </v-col>
    </v-row>

    <div class="pb-4">
      <v-divider></v-divider>
    </div>

    <v-row>
      <v-col sm="12" md="6">
        <div class="text-h6 text-right mt-2">Notificar a:</div>
      </v-col>
      <v-col sm="12" md="6">
        <div class="text-h6">
          <v-btn
            color="success"
            icon="mdi-plus-circle"
            variant="text"
            @click="addNotif"
          ></v-btn>
          <v-btn
            color="error"
            icon="mdi-minus-circle"
            variant="text"
            @click="remNotif"
            v-if="state.notify.length > 1"
          ></v-btn>
        </div>
      </v-col>
    </v-row>

    <div class="text-subtitle-1 text-center my-2">
      <p>Terminando de notificar a los responsables de operaciones</p>
      <p>¿En que orden se notificara al resto de los involucrados?</p>
    </div>

    <v-row>
      <v-col sm="12" md="6" offset="md-3">
        <v-list lines="one">
          <v-list-item v-for="(item, index) in state.notify" :key="index">
            <v-select
              v-model="item.selection"
              :items="persons"
              item-title="name"
              item-value="id"
              label="Notificar a"
            ></v-select>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row justify="end" class="pb-4">
      <InertiaLink :href="formatsApi.list.pathTemplate" class="inertia-link" as="v-btn">
        <v-btn class="me-4" color="grey-darken-2"> Regresar </v-btn>
      </InertiaLink>
      <v-btn class="me-4" type="submit" color="primary"> Guardar </v-btn>
    </v-row>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { format as formatFns} from "date-fns";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { formats as formatsApi } from "../../api";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const props = defineProps(["format", "positions", "persons", "action"]);

const addNotif = () => (state.value.notify.push({ selection: ""}));
const remNotif = () => (state.value.notify.pop());

const requiredMessage = "Este campo es necesario para continuar";

const formatDate = (date) => {
  return date === undefined ? "" : formatFns(date, "dd/MM/yyyy");
};

const setNotify = (ary) => {
  return props.action === "edit" ? ary.map((item) => ({selection: item})) : [{ selection: ""}]
}

const setInitialDate = (timestamp) => {
  return props.action === "edit" ? new Date(timestamp) : new Date()
}

const setTerminationDate = () => state.value.termination_date = state.value.permanent ? undefined : new Date()

const initialState = {
  employee_name: props.format.employee_name,
  employee_number: props.format.employee_number,
  current_position_id: props.format.current_position_id,
  new_position_id: props.format.new_position_id,
  permanent: props.format.permanent || true,
  training: props.format.training || false,
  job_posting: props.format.job_posting || false,
  justification: props.format.justification,
  notify: setNotify(props.format.notify),
  creation_date: setInitialDate(props.format.creation_date),
  termination_date: setInitialDate(props.format.termination_date),
  current_boss_id: props.format.current_boss_id,
  vacant_boss_id: props.format.vacant_boss_id,
  vacant_coordinator_id: props.format.vacant_coordinator_id,
}

const state = ref({ ...initialState });

const rules = {
  employee_name: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  employee_number: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  current_position_id: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  new_position_id: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  permanent: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  training: {},
  job_posting: {},
  justification: {
    required: helpers.withMessage(requiredMessage, required),
    minLengthValue: helpers.withMessage(
      "Este campo debe tener al menos 10 caracters",
      minLength(10)
      ),
    $autoDirty: true,
  },
  notify: {},
  creation_date: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  termination_date: {},
  current_boss_id: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  vacant_boss_id: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
  vacant_coordinator_id: {
    required: helpers.withMessage(requiredMessage, required),
    $autoDirty: true,
  },
};

const v$ = useVuelidate(rules, state);

const submit = async () => {
  const validForm = await v$.value.$validate();
  if (!validForm) return;

  const notifyValues = [
    state.value.vacant_boss_id,
    state.value.current_boss_id,
    state.value.vacant_coordinator_id
  ].concat(state.value.notify.map((el) => el.selection))

  const form = inertiaForm({
    format: {
      ...state.value,
      notify: notifyValues
    },
  });
  // alert(JSON.stringify({ form }, null, 2));
  if (props.action === "edit") {
    const { id } = props.format;
    formatsApi.update({ params: { id }, form });
  } else if (props.action === "new") {
    formatsApi.create({ form });
  }
};
</script>
