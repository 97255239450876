<template>
  <v-row align="center" justify="center" style="height: 100vh" bg-color="red">
    <v-col sm="12" md="4">
      <div align="center">
        <v-img aspect-ratio="1" width="150" :src="logo_uri" />
      </div>
      <div class="text-h6 text-center">Introduce tu correo</div>
      <div class="text-subtitle-2 text-center">Te enviaremos las intrucciones para recuperar tu contraseña</div>
      <form @submit.prevent="submit" class="py-4">
        <v-text-field
          name="email"
          label="email"
          v-model="values.email"
          :error-messages="v$.email.$errors.map((e) => e.$message)"
          autocomplete="email"
        ></v-text-field>
        <div class="text-center py-4">
          <v-btn block size="large" class="me-4" type="submit" color="primary">
            Recuperar
          </v-btn>
        </div>
      </form>
      <div class="text-right">
        <InertiaLink href="/login" class="inertia-link-red text-body-2" as="a">
          Regresar
        </InertiaLink>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref } from "vue"
import { useVuelidate } from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import Layout from "../../Layouts/Blank.vue";
import { usersPasswords as passwordsApi } from "../../api";

defineOptions({ layout: Layout });
defineProps(["logo_uri"])

const initialState = {
  email: "",
}

const values = ref(initialState)

const rules = {
  email: {
    required: helpers.withMessage("El email es requerido", required),
    email: helpers.withMessage("Ingresa un email valido", email),
    $autoDirty: true,
  }
}

const v$ = useVuelidate(rules, values);

const submit = async () => {
  const validForm = await v$.value.$validate();
  if (!validForm) return;

  const form = inertiaForm({
    user: { ...values.value },
  });
  passwordsApi.create({ form });
};
</script>
