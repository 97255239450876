<script setup>
import { ref } from "vue";
import { router } from "@inertiajs/vue3";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
const props = defineProps(["items", "headers", "paths", "toolBar", "can"]);

const getItemkeys = (item) => {
  return Object.getOwnPropertyNames(item).filter((k) => k !== "id");
};

const month = ref({
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
});

const monthNames = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

const formatSelect = (month) => {
  return `${monthNames[month?.month]} - ${month?.year}`;
};

const getData = () => {
  router.get(
    props.paths.list.path(), {
      month: month.value.month + 1,
      year: month.value.year,
    },
    { preserveState: true }
  );
};

const handleDate = (modelData) => {
  month.value = modelData;
  getData();
};
</script>

<template>
  <v-toolbar color="surface" class="mb-2">
    <v-spacer></v-spacer>
    <InertiaLink
      :href="paths?.new?.path() || '#'"
      as="v-btn"
      v-if="can?.create"
    >
      <v-btn prepend-icon="mdi-plus" color="primary" stacked size="small">
        Crear
      </v-btn>
    </InertiaLink>
  </v-toolbar>
  <v-toolbar density="compact" v-if="toolBar?.calendar || toolBar?.search">
    <v-row>
      <v-col md="4" sm="12" v-if="toolBar?.calendar">
        <VueDatePicker
          v-model="month"
          month-picker
          :teleport="true"
          :max-date="new Date()"
          auto-apply
          locale="es"
          position="center"
          @update:model-value="handleDate"
        >
          <template #trigger>
            <v-btn
              prepend-icon="mdi-calendar"
              size="x-large"
              color="primary"
              class="text-subtitle-1"
              >{{ formatSelect(month) }}</v-btn
            >
          </template>
        </VueDatePicker>
      </v-col>
      <v-col md="4" offset="md-4" sm="12" v-if="toolBar?.search">
        <v-text-field
          hide-details
          label="Buscar"
          prepend-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-toolbar>
  <v-table fixed-header>
    <thead>
      <tr>
        <th class="text-left" v-for="(header, index) in headers" :key="index">
          {{ header }}
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td v-for="(key, index) in getItemkeys(item)" :key="index">
          <div  v-if="key === 'finished'">
            <v-chip v-if="item[key] == true" color="success">Completado</v-chip>
            <v-chip v-else>En progreso</v-chip>
          </div>
          <div v-else>{{ item[key] }}</div>
        </td>
        <td>
          <InertiaLink
            :href="paths.get.path({ id: item.id })"
            class="inertia-link"
            as="v-btn"
            type="v-btn"
            v-if="can.read"
          >
            <v-btn size="small" icon="mdi-eye-outline" variant="text"> </v-btn>
          </InertiaLink>
          <InertiaLink
            :href="paths.edit.path({ id: item.id })"
            as="v-btn"
            type="v-btn"
            v-if="can.update && item.finished !== true"
          >
            <v-btn size="small" icon="mdi-circle-edit-outline" variant="text">
            </v-btn>
          </InertiaLink>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>
