// JsFromRoutes CacheKey f7c24c52294a405e80ff981869cff1d3
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  list: definePathHelper('get', '/usuarios'),
  create: definePathHelper('post', '/usuarios'),
  new: definePathHelper('get', '/usuarios/new'),
  edit: definePathHelper('get', '/usuarios/:id/edit'),
  get: definePathHelper('get', '/usuarios/:id'),
  update: definePathHelper('patch', '/usuarios/:id'),
}
