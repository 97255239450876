<template>
  <v-row v-if="$page.props.flash?.alert" :class="getAlertClass($page.url)">
    <v-alert
      closable
      type="error"
      variant="tonal"
      density="compact"
      :text="$page.props.flash?.alert"
    />
  </v-row>

  <v-row v-if="$page.props.flash?.success" :class="getAlertClass($page.url)">
    <v-alert
      closable
      type="success"
      variant="tonal"
      density="compact"
      :text="$page.props.flash?.success"
    />
  </v-row>
</template>

<script setup>
const getAlertClass = (uri) => uri === "/login" ? "pa-4 mt-4" : "pa-4"
</script>
