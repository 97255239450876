<template>
  <div id="fumsByTaken">
    <div class="d-flex justify-end mb-6">
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-palette-outline" v-bind="props" size="x-small"></v-btn>
        </template>

        <v-sheet min-width="300">
        <v-color-picker v-model="color" hide-canvas show-sliders hide-inputs hide-swatches
          @update:modelValue="updateOptions"></v-color-picker>
        </v-sheet>
      </v-menu>
    </div>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script setup>
import { ref } from "vue"
const props = defineProps(["data"])

const color = ref("#c62828")

const series = [{
  name: 'FUMs',
  data: Object.values(props.data)
}]

const chartOptions = ref({
  chart: {
    height: 400,
    type: 'bar',
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },
  dataLabels: {
    enabled: true,
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
  colors: [color.value],
  xaxis: {
    categories: Object.keys(props.data),
    position: 'bottom',
    labels: {
    formatter: function (value) {
      return value + " Dias";
    }
  },
    axisBorder: {
      show: true
    },
    axisTicks: {
      show: false
    },
    tooltip: {
      enabled: true,
    }
  },
  yaxis: {
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
  },
  title: {
    text: 'FUMs por dias que tardó en completar',
    align: 'left'
  },
})

const updateOptions = () => {
  chartOptions.value = {
    colors: [color.value],
  }
}
</script>
