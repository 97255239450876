<template>
<v-list lines="one">
    <v-list-item v-for="item in details">
        <template v-slot:prepend>
            <v-icon color="primary">mdi-account-outline</v-icon>
        </template>
        <template v-slot:title>
            <div v-html="getContent(item)"></div>
        </template>
    </v-list-item>
</v-list>
</template>
<script setup>
import { format as formatFns } from "date-fns";
defineProps(["details"]);

const getDate = (timestamp) => {
    const date = new Date(timestamp);
    return formatFns(date, "dd/MM/yyyy");
};

const getContent = (item) => {
    return `<span class="text-bold">${item.name}</span> &mdash; <span class="text-primary">${getDate(item.created_at)}</span>`;
}
</script>
