// JsFromRoutes CacheKey ecc3669bb52cc81987a4c72c655c9253
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  list: definePathHelper('get', '/ninja'),
  approveAndCloseFormat: definePathHelper('post', '/ninja/approve_and_close_format'),
  sendWelcomeInstructions: definePathHelper('post', '/ninja/send_welcome_instructions'),
}
