<template>
  <v-row align="center" justify="center" style="height: 100vh">
    <v-col sm="12" md="4">
      <div align="center">
        <v-img aspect-ratio="1" width="150" :src="logo_uri" />
      </div>
      <form @submit.prevent="submit">
        <v-text-field
          name="email"
          label="email"
          v-model="values.email"
          :error-messages="v$.email.$errors.map((e) => e.$message)"
          autocomplete="email"
        ></v-text-field>

        <v-text-field
          name="password"
          label="password"
          v-model="values.password"
          :error-messages="v$.password.$errors.map((e) => e.$message)"
          autocomplete="current-password"
          :type="show ? 'text' : 'password'"
          :append-inner-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append-inner="show = !show"
        ></v-text-field>

        <div class="text-center py-4">
          <v-btn block size="large" class="me-4" type="submit" color="primary">
            Conectar
          </v-btn>
        </div>
      </form>
      <div class="text-right">
        <InertiaLink :href="passwordsApi.new.path()" class="inertia-link-red text-body-2" as="a">
          Olvide mi contraseña
        </InertiaLink>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref } from "vue"
import { useVuelidate } from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { usersSessions as sessionsApi } from "../../api";
import { usersPasswords as passwordsApi } from "../../api";
import Layout from "../../Layouts/Blank.vue";

defineOptions({ layout: Layout });
defineProps(["logo_uri"])
const show = ref(false);
const initialState = {
  email: "",
  password: ""
}

const values = ref(initialState)

const rules = {
  email: {
    required: helpers.withMessage("El email es requerido", required),
    email: helpers.withMessage("Ingresa un email valido", email),
    $autoDirty: true,
  },
  password: {
    required: helpers.withMessage("La contraseña es requerida", required),
    $autoDirty: true,
  }
}

const v$ = useVuelidate(rules, values);

const submit = async () => {
  const validForm = await v$.value.$validate();
  if (!validForm) return;

  const form = inertiaForm({
    user: { ...values.value },
  });
  sessionsApi.create({ form });
};
</script>
