// JsFromRoutes CacheKey e55e9dc02cd6b923d5c87c0397fb7797
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  list: definePathHelper('get', '/cedis'),
  create: definePathHelper('post', '/cedis'),
  new: definePathHelper('get', '/cedis/new'),
  edit: definePathHelper('get', '/cedis/:id/edit'),
  get: definePathHelper('get', '/cedis/:id'),
  update: definePathHelper('patch', '/cedis/:id'),
}
