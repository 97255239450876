<script setup>
import { computed } from "vue";
import Layout from "../Layouts/Blank.vue";

defineOptions({ layout: Layout });
const props = defineProps(["status"]);

const title = computed(() => {
  return {
    403: "403",
    404: "404",
    500: "500",
    503: "503",
  }[props.status];
});
const description = computed(() => {
  return {
    403: "Lo sentimos, no tienes permiso para visitar esta pagina",
    404: "Lo sentimos, la pagina que estas buscando no existe.",
    500: "!Ups!, parece que el servidor esta fallando",
    503: "Lo sentimos, estamos dando mantenimiento. Regresa mas tarde",
  }[props.status];
});
</script>

<template>
  <v-row
    align="center"
    justify="center"
    style="height: 100vh"
    class="linear-gradient"
  >
    <v-col sm="12" md="4">
      <div class="text-h1 mx-auto text-center">{{ title }}</div>
      <div class="text-center py-8">{{ description }}</div>
      <div class="text-center">
        <InertiaLink href="/" class="inertia-link" as="v-btn"
          ><v-btn variant="text" color="primary">
            Ir al inicio
          </v-btn></InertiaLink
        >
      </div>
    </v-col>
  </v-row>
</template>
