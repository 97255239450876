<template>
  <form @submit.prevent="submit">
    <v-text-field label="Nombre" v-model="state.name"
      :error-messages="v$.name.$errors.map((e) => e.$message)"></v-text-field>
    <v-row justify="end" class="pb-4">
      <InertiaLink :href="props.paths.list.pathTemplate" class="inertia-link" as="v-btn">
        <v-btn class="me-4" color="grey-darken-2"> Regresar </v-btn>
      </InertiaLink>
      <v-btn class="me-4" type="submit" color="primary"> Guardar </v-btn>
    </v-row>
  </form>
</template>

<script setup>
import { ref } from "vue"
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";

const props = defineProps(["item", "resource", "action", "paths"]);

const initialState = {
  name: props.item?.name
}

const state = ref(initialState)

const rules = {
  name: {
    required: helpers.withMessage("El nombre es requerido", required),
    $autoDirty: true,
  }
}

const v$ = useVuelidate(rules, state);

const sameValues = () => JSON.stringify(state.value) === JSON.stringify(initialState);

const submit = async () => {
  const validForm = await v$.value.$validate();
  if (!validForm) return;
  if (props.action === "edit" && sameValues) return;

  const myForm = new Object();
  myForm[props.resource] = { ...state.value }

  const form = inertiaForm({ ...myForm });

  if (props.action === "edit") {
    const { id } = props.item;
    props.paths.update({ params: { id }, form });
  } else if (props.action === "new") {
    props.paths.create({ form })
  }
};
</script>
