<template>
    <v-list lines="two">
        <v-list-item v-for="item in details" :title="item.name">
            <template v-slot:prepend>
                <v-icon color="primary">mdi-account-outline</v-icon>
            </template>
            <template v-slot:subtitle>
                <div v-html="getContent(item)"></div>
            </template>
        </v-list-item>
    </v-list>
</template>
<script setup>
import { format as formatFns } from "date-fns";
defineProps(["details"]);

const getDate = (timestamp) => {
    const date = new Date(timestamp);
    return formatFns(date, "dd/MM/yyyy");
};

const getContent = (item) => {
    return `<span class="text-primary">${getDate(item.created_at)}</span> &mdash; ${item.content}`;
}
</script>
