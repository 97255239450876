<template>
  <v-sheet class="pa-1">
    <div v-if="props.details.length > 0">
      <div class="text-h6 pb-8">Aprobado por</div>
      <v-row class="pb-4" justify="start">
        <v-col v-for="item in props.details" :key="item.id" cols="6" md="3">
          <v-sheet
            elevation="2"
            rounded
            class="mx-auto pt-1"
            height="100"
            width="100"
          >
            <div class="text-center py-2">
              <v-icon color="primary">mdi-account</v-icon>
            </div>
            <div class="text-center text-body-2">
              {{ item.name }}
            </div>
            <div class="text-center text-body-2">
              {{ getDate(item.created_at) }}
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <div v-else class="text-h6 pb-8">Aun no ha sido aprobado</div>
    <form @submit.prevent="submit" v-if="props.can.approve">
      {{ authMessage }}
      <v-text-field
        v-model="state.content"
        label="Aprobar"
        :hint=authMessage
        :error-messages="v$.content.$errors.map((e) => e.$message)"
      ></v-text-field>
      <v-row justify="end" class="pb-4">
        <v-btn
          class="me-3"
          type="submit"
          color="primary"
          :disabled="!(state.content === 'AUTORIZO')"
        >
          Aprobar
        </v-btn>
      </v-row>
    </form>
    <div v-else class="text-center text-h6 pb-8">Ya has aprobado este FUM</div>
  </v-sheet>
</template>

<script setup>
import { ref } from "vue"
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { useForm as inertiaForm } from "@inertiajs/vue3";
import { format as formatFns} from "date-fns";
import { formatJournals } from "~/api";

const props = defineProps(["format", "details", "can"]);

const authMessage = "Escribe AUTORIZO para dar tu aprobación";

const getDate = (timestamp) => {
  const date = new Date(timestamp);
  return formatFns(date, "dd/MM/yyyy");
};

const initialState = {
  content: ""
}

const state = ref(initialState)

const equalsValue = (value) => value === "AUTORIZO"

const rules = {
  content: {
    equalsValue: helpers.withMessage(authMessage, equalsValue),
    $autoDirty: true,
  }
}

const v$ = useVuelidate(rules, state);

const submit = async () => {
  const form = inertiaForm({
    format_journal: {
      category: "approval",
      format_id: props.format.id,
    },
  });
  formatJournals.create({ form });
};
</script>
